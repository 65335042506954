var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "extra-component-chartist-demo" } }, [
    _vm._m(0),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Line Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "line",
                  height: "350",
                  options: _vm.apexChatData.lineChartSimple.chartOptions,
                  series: _vm.apexChatData.lineChartSimple.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.lineChartSimpleCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Line Area Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "area",
                  height: "350",
                  options: _vm.apexChatData.lineAreaChartSpline.chartOptions,
                  series: _vm.apexChatData.lineAreaChartSpline.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.lineAreaChartSplineCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Column Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "bar",
                  height: "350",
                  options: _vm.apexChatData.columnChart.chartOptions,
                  series: _vm.apexChatData.columnChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.columnChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Bar Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "bar",
                  height: "350",
                  options: _vm.apexChatData.barChart.chartOptions,
                  series: _vm.apexChatData.barChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.barChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Mixed Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "line",
                  height: "350",
                  options: _vm.apexChatData.mixedChart.chartOptions,
                  series: _vm.apexChatData.mixedChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.mixedChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Candlestick Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "candlestick",
                  height: "350",
                  options: _vm.apexChatData.candlestickChart.chartOptions,
                  series: _vm.apexChatData.candlestickChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.candlestickChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "3D Bubble Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "bubble",
                  height: "350",
                  options: _vm.apexChatData.bubbleChart.chartOptions,
                  series: _vm.apexChatData.bubbleChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.bubbleChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Scatter Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "scatter",
                  height: "350",
                  options: _vm.apexChatData.scatterChart.chartOptions,
                  series: _vm.apexChatData.scatterChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.scatterChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Heat Map Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "heatmap",
                  height: "350",
                  options: _vm.apexChatData.heatMapChart.chartOptions,
                  series: _vm.apexChatData.heatMapChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.heatMapChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Pie Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "pie",
                  height: "350",
                  options: _vm.apexChatData.pieChart.chartOptions,
                  series: _vm.apexChatData.pieChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.pieChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Donut Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "donut",
                  height: "350",
                  options: _vm.apexChatData.donutChart.chartOptions,
                  series: _vm.apexChatData.donutChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.donutChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Radial Bar Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "radialBar",
                  height: "350",
                  options: _vm.apexChatData.radialBarChart.chartOptions,
                  series: _vm.apexChatData.radialBarChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.radialBarChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Radar Chart", "code-toggler": "" } },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "radar",
                  height: "350",
                  options: _vm.apexChatData.radarChart.chartOptions,
                  series: _vm.apexChatData.radarChart.series,
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  "\n" +
                    _vm._s(_vm.apexChatData.radarChartCode) +
                    "\n                    "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _vm._v("A Vue.js component for ApexCharts. Read full documnetation "),
      _c(
        "a",
        {
          attrs: {
            href: "https://apexcharts.com/docs/installation/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }